import { render, staticRenderFns } from "./corpRenewWin.vue?vue&type=template&id=5c4bae70&scoped=true"
import script from "./corpRenewWin.vue?vue&type=script&setup=true&lang=js"
export * from "./corpRenewWin.vue?vue&type=script&setup=true&lang=js"
import style0 from "./corpRenewWin.vue?vue&type=style&index=0&id=5c4bae70&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c4bae70",
  null
  
)

export default component.exports